@use '~@ht6/react-ui/dist/styles/mixins.module';
@use '~@ht6/react-ui/dist/styles/colors.module';
@use '../../../styles/mixins.module' as localMixins;
@use '../../../styles/colors.module' as localColors;

.root {
  grid-template-columns: 3fr;
  text-align: center;
  grid-gap: 1rem;
  display: grid;

  &--left {
    text-align: left;
  }
}

.teamFormationDescription {
  max-width: 612px;
  margin: 0 auto;
}

.title {
  margin-bottom: 0.625rem;
}

.buttons {
  grid-template-columns: max-content max-content max-content;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  display: grid;
  gap: 2.5rem;

  @include localMixins.media(tablet) {
    grid-template-columns: 1fr;
    gap: 1.25rem;
  }
}

.join {
  grid-template-columns: auto max-content;
  grid-gap: 1.5rem;
  display: grid;
  width: 75%;

  @include localMixins.media(tablet) {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }
}

.joinBtn {
  margin-top: auto;
}

.link {
  text-decoration: none;

  &:hover,
  &:focus {
    cursor: pointer;
  }
}

.footer {
  margin-top: 1.5rem;
}

.continueSoloBtn {
  margin: 0;
  border: 0;
  padding: 0;
}

.continueSoloBtn Button {
  text-decoration: underline;
  background: none;
  @include mixins.transition(color);
  color: colors.getColor(neutral-400);

  &:hover {
    background: none !important;
  }
}

.dateHighlight {
  @include mixins.transition(color);
  color: colors.getColor(warning-400);
}

.teamScreenContainer,
.joinScreenContainer {
  height: 332px;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
}

.teamScreenContainer {
  gap: 2rem; 
  position: relative;

  @include localMixins.media(tablet) {
    gap: 4rem;
  }
}

.joinScreenContainer {
  align-items: center;
  gap: 1rem;
}

.teamScreenContent {
  display: flex;
  justify-content: space-around;

  @include localMixins.media(tablet) {
    flex-direction: column;
    gap: 4rem;
  }
}

.leaveTeamContainer {
  display: none;
  width: fit-content;
  height: fit-content;
  background-color: localColors.$content-bg;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 2rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 1;
}

.leaveTeamButtonContainer {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;

  @include localMixins.media(tablet) {
    flex-direction: column-reverse;
    gap: 1rem;
  }
}
