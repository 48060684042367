.root {
  background-color: white;
  overflow: hidden;
}

:global {
  .notion-page-cover {
    min-height: unset;
    overflow: hidden;
    height: unset;
  }
}