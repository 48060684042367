@use '../../styles/vars.module' as localVars;

.container {
  box-sizing: border-box;
  padding: 0 1.75rem;
  width: 100%;
}

.content {
  margin: 0 auto;
  width: 100%;

  @each $tag, $val in localVars.$widths {
    &--#{$tag} {
      max-width: $val;
    }
  }
}
