@use '~@ht6/react-ui/dist/styles/colors.module';
@use '~@ht6/react-ui/dist/styles/fonts.module';
@use '../../styles/mixins.module';

@include fonts.init('~@ht6/react-ui/dist/assets/fonts/');

:root {
  @include colors.init;
}

:global() {
  html,
  body {
    min-height: 100%;
  }

  html {
    @include mixins.media(tablet) {
      font-size: 14px;
    }

    @include mixins.media(mobile) {
      font-size: 12px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  html,
  body {
    padding: 0;
    margin: 0;
  }
}

.toast {
  grid-template-columns: auto;
  align-items: center;
  grid-gap: 0.5rem;
  display: grid;
}

.icon {
  grid-template-columns: auto auto;
}
