@use '../../styles/mixins.module' as localMixins;
@use '../../styles/vars.module';
@use '~@ht6/react-ui/dist/styles/colors.module';

.container {
  display: table;
  height: 100%;
  width: 100%;
}

.content {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  padding: 15%;
}

.toastcontent{
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  padding: 2%;
}

.heading {
  margin-bottom: 1rem;
}

.textsuccess{
  color: colors.getColor(success) !important
}

.textwarning{
  color: colors.getColor(warning-400) !important
}

.texterror{
  color: colors.getColor(error-500) !important
}

.buttoncontainer{
  text-align: center;
}

.button{
  display: inline-block;
  vertical-align: middle;
  @include localMixins.media(tablet) {
    width: 100%
  }
}

.socials{
  margin: auto;
  width:50%;
  @include localMixins.media(tablet) {
    width: 90%
  }
}

.icon{
  display: inline-block;
  vertical-align: middle;
  padding: 15px;
  @include localMixins.media(tablet) {
    padding: px;
  }
}

.whiteicon{
  color: colors.getColor(neutral-50) !important;
}

.greylink{
  color: colors.getColor(neutral-400);
  text-decoration: underline;
  text-decoration-color: colors.getColor(neutral-400);
}


.root {
  grid-template-columns: 1fr;
  box-sizing: border-box;
  padding: 3.75rem 0;
  grid-gap: 3.75rem;
  display: grid;
}

.label {
  @include localMixins.media(tablet) {
    display: none;
  }
}

.banner {
  max-width: map-get(vars.$widths, form) * 0.7;
  margin: 0 auto 2.25rem;
}

.list {
  margin: 0.5rem 0 0;
  padding-left: 1.5rem;

  & li {
    text-decoration: underline;
    cursor: pointer;
  }
}

.footer {
  margin-top: 2.25rem;
}
