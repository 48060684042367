@use '~@ht6/react-ui/dist/styles/colors.module';
@use '../../../styles/mixins.module' as localMixins;
@use '../../../styles/colors.module' as localColors;

.event {
  @each $event, $value in localColors.$eventColorMap {
    &--#{$event} {
      color: map-get($value, 'borderColor')
    }
  }
}

.root {
  grid-template-columns: minmax(1px, auto);
  display: grid;
}

.event {
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 0.75rem;
  display: flex;
  height: 100%;
}

.text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.buttons {
  justify-content: space-between;
  display: flex;
}

.button {
  padding: 0.5rem 1.25rem;
  border-radius: 999px;
  margin-top: 1rem;

  & > span {
    display: flex;
    align-items: center;
  }
}

.iconl {
  margin-right: 0.25rem;
  margin-left: -0.25rem;
  height: 100%;
}

.iconr {
  margin-right: -0.25rem;
  margin-left: 0.25rem;
  height: 100%;
}

.tabs {
  display: flex;
  width: 100%;

  color: white;

  border-bottom: 1px solid #999;

  div {
    flex-grow: 1;
    text-align: center;

    padding: 1rem;

    cursor: pointer;
    border-bottom-width: 0.25rem;
    border-bottom-style: solid;
    border-bottom-color: #06162f;

    transition: color .5s ease, border-bottom-color .5s ease, border-bottom-style .5s ease;

    &.active, &:hover {
      color: colors.getColor(warning-400);
      border-bottom-color: colors.getColor(warning-400);
    }
  }
}

.scheduleHeading {
  margin-bottom: 4rem;
  
  button {
    margin-top: 1rem;
  }
}

.scheduleRoot {
  display: flex;
  flex-direction: row-reverse;

  gap: 20px;

  align-items: flex-start;
  
  @include localMixins.media(laptop) {
    flex-direction: column;
    align-items: stretch;
  }
}

.legendContainer {
  padding: 24px 32px;

  background-color: #313E53;
  border-radius: 12px;

  flex-basis: 13vw;
  flex-shrink: 0;

  @include localMixins.media(desktop) {
    flex-basis: 10vw;
  }

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  span {
    margin-right: 0.25rem;
  }
}

.legendScrollButton {
  margin-top: 1.75rem;
}

.scheduleContainer {
  flex-grow: 1;
}

.schedule {
  max-height: 80vh;
  max-height: 80dvh;
  margin-top: 16px;
  padding-right: 24px;

  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 15px;
  }
  
  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.18);
  }
  
  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background: #999faa;
  }
  
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: darken(#999faa, 10%);
  }  
}